
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: "Spacia";
  src: url("./assets/fonts/spacia/Spacia-Regular.otf") format('OpenType');
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* include border and padding in element width and height */
* {
  box-sizing: border-box;
}

body {
  background:var(--neutral-700);
}

:root{
  --neutral-100: #DCDFE5;
  --neutral-200: #C5C9D3;
  --neutral-300: #AEB3C2;
  --neutral-400: #969EB0;
  --neutral-500: #7D869C;
  --neutral-600: #69738C;
  --neutral-700: #586074;
  --neutral-800: #464D5D;
  --neutral-900: #353946;
  --primary-100: #BFC6D9;
  --primary-200: #A6B0C9;
  --primary-300: #8C99BA;
  --primary-400: #7383AB;
  --primary-500: #586994;
  --primary-600: #4D5B80;
  --primary-700: #3D4966;
  --primary-800: #2E374D;
  --primary-900: #1F2433;
  --accent1-500: #1FFFDD;
  --accent1-300: #70FFEA;
  --accent1-700: #00CCAD;
  --accent1-900: #008F79;
  --front-100: #f4f4f4;
  --front-200: #EBEBEB;
  --front-300: #E0E0E0;
  --front-400: #D6D6D6;

  --green-primary-100:#ADFFAD;
  --green-primary-200:#85FF85;
  --green-primary-300:#5CFF5C;
  --green-primary-400:#33FF33;
  --green-primary-500:#00FF00;
  --green-primary-600:#00E000;
  --green-primary-700:#00B800;
  --green-primary-800:#008F00;
  --green-primary-900:#006600;
  --green-primary-1000:#003D00;

  --indigo-dye: rgba(39, 64, 96, 1);
  --bdazzled-blue: rgba(51, 92, 129, 1);
  --french-sky-blue: rgba(101, 175, 255, 1);
  --space-cadet: rgba(27, 40, 69, 1);
  --blue-gray: rgba(88, 153, 226, 1);
  
  --red-primary-300: #EB5C68;
  --red-primary-400: #E94957;
  --red-primary-500: #E63946;
  --red-primary-600: #E42535;
  --red-primary-700: #DA1B2B;
  --red-primary-800: #C81927;
  --red-primary-900: #B61624;
  --red-primary-1000: #A31420;
}
.FSize-1rem{
  font-size: 1rem;
}